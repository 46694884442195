const themestyle = {
  colors: {
    primary: "#3326C3",
    secondary: "#DE0093",
    background: "#F9EDFF",
    lightpink: "#F1D4FF",
    textPrimary: "#000000",
    textSecondary: "#636363",
    purple: "#651CB5",
    white: "#FFFFFF",
    fcolor: "#263238",
    labelcolor: "#344054",
    formcolor: "rgba(0, 0, 0, 0.87)",
    formcolor1: "rgba(0, 0, 0, 0.23)",
    navbarcolor: "linear-gradient(275.81deg, #3326C3 2.78%, #DE0093 95.23%)",
    circlecolor: "#F4EFFF",
  },
  fontSizes: {
    small: "18px",
    medium: "24px",
    large: "32px",
    xlarge: "41px",
    xxlarge: "48px",
  },
  spacing: {
    small: "10px",
    medium: "20px",
    large: "30px",
    xlarge: "40px",
  },
  borderRadius: "100px",
};
export default themestyle;