import React, { useState } from 'react';
import { Box, Button, OutlinedInput, FormControl, FormHelperText, useTheme, useMediaQuery } from '@mui/material';
import FormDataProps from '../interfaces/formdataProps';
import { Form_MESSAges } from '../const';
import themestyle from '../pages/theme';

const Formdata: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [formData, setFormData] = useState<FormDataProps>({
    fullName: '',
    phoneNumber: '',
    email: '',
    companyName: '',
  });
  const [errors, setErrors] = useState<Partial<FormDataProps>>({});

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const validate = () => {
    let tempErrors: Partial<FormDataProps> = {};
    if (!formData.fullName) {
      tempErrors.fullName = Form_MESSAges.NAME_ERR;
    }
    if (!formData.phoneNumber) {
      tempErrors.phoneNumber = Form_MESSAges.PHONE_ERR;
    } else if (!/^\d+$/.test(formData.phoneNumber)) {
      tempErrors.phoneNumber = Form_MESSAges.PHONE_ERR1;
    }
    if (!formData.email) {
      tempErrors.email = Form_MESSAges.EMAIL_ERR;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors.email = Form_MESSAges.EMAIL_ERR1;
    }
    if (!formData.companyName) {
      tempErrors.companyName = Form_MESSAges.COMPANY_ERR;
    }
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      console.log(formData);
    }
  };

  const inputFields = [
    {
      name: 'fullName', 
      label: Form_MESSAges.FULL_NAME,
      placeholder: Form_MESSAges.Fullname_plc,
      value: formData.fullName,
      error: !!errors.fullName,
      helperText: errors.fullName,
    },
    {
      name: 'phoneNumber', 
      label: Form_MESSAges.Phone_no,
      placeholder: Form_MESSAges.Phone_plc,
      value: formData.phoneNumber,
      error: !!errors.phoneNumber,
      helperText: errors.phoneNumber,
    },
    {
      name: 'email', 
      label: Form_MESSAges.Email,
      placeholder: Form_MESSAges.email_plc,
      value: formData.email,
      error: !!errors.email,
      helperText: errors.email,
    },
    {
      name: 'companyName',
      label: Form_MESSAges.company_name,
      placeholder: Form_MESSAges.company_plc,
      value: formData.companyName,
      error: !!errors.companyName,
      helperText: errors.companyName,
    },
  ];

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& .MuiFormControl-root': { width: '300px', marginBottom: '20px' },
        '& .MuiButton-root': { m: 1, width: '300px' },
      }}
    >
      {inputFields.map((field) => (
        <FormControl key={field.name} error={field.error}>
          <FormHelperText style={{ color: themestyle.colors.labelcolor, fontWeight: 600, fontSize: '16px' }}>
            {field.label}
          </FormHelperText>
          <OutlinedInput
            placeholder={field.placeholder}
            name={field.name}
            value={field.value}
            onChange={handleChange}
            sx={{
              borderRadius: '100px',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: field.error ? 'red' : themestyle.colors.formcolor1,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: themestyle.colors.formcolor,
              },
              '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: themestyle.colors.formcolor,
              },
            }}
          />
          {field.error && <FormHelperText error>{field.helperText}</FormHelperText>}
        </FormControl>
      ))}
      <Button
        variant="contained"
        type="submit"
        style={{
          textTransform: 'none',
          borderRadius: '100px',
          background: themestyle.colors.navbarcolor,
          height: isSmallScreen ? "" : "60px"
        }}
      >
        {Form_MESSAges.button_txt}
      </Button>
    </Box>
  );
};

export default Formdata;