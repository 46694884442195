import React from 'react';
import { Card, CardContent, Typography, Box, Button, useTheme, useMediaQuery } from '@mui/material';
import { PhoenixCardProps } from '../interfaces/PheonixCardProps';
import themestyle from '../pages/theme';
import { MarginTwoTone } from '@mui/icons-material';

const PhoenixCard: React.FC<PhoenixCardProps> = ({ title, description, description1, mediaUrl, style, showButton, buttonText, bulletPoints, amount }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return (
        <Card style={{
            borderRadius: "16px",
            textAlign: "left",
            padding: "20px",
            width: style?.width || (isSmallScreen ? "90%" : "328px"),
            height: style?.height || "211px",
            position: "relative",
            margin: isSmallScreen ? "0 auto" : "",
            ...style,
        }}>
            <CardContent>
                {mediaUrl && (
                    <Box component="img" src={mediaUrl} alt={title} width="100" height="100" mb={1} />
                )}
                <Typography gutterBottom variant="h5" component="div" style={{ color: "#252B42", fontWeight: 700 }}>
                    {title}
                </Typography>
                {description && (
                    <Typography variant="body2">
                        {description}
                    </Typography>
                )}
                {description1 && (
                    <Typography variant="body2" style={{ color: themestyle.colors.purple }}>
                        {description1}
                    </Typography>
                )}

                {amount && (
                    <Box sx={{
                        position: 'absolute', top: '20px', right: '20px', background: themestyle.colors.navbarcolor, marginTop: "10px",
                        borderRadius: title === 'Basic' ? '38px' : '50%', width: title === 'Basic' ? '90px' : '60px', height: title === 'Basic' ? '40px' : '60px',
                        display: 'flex', justifyContent: 'center', alignItems: 'center',
                        border: '1px solid transparent', boxSizing: 'border-box'
                    }}>
                        <Box sx={{
                            backgroundColor: themestyle.colors.circlecolor, borderRadius: title === 'Basic' ? '38px' : '50%',
                            width: title === 'Basic' ? 'calc(120% - 8px)' : 'calc(100% - 4px)',
                            height: title === 'Basic' ? 'calc(100% - 2px)' : 'calc(100% - 4px)', display: 'flex', justifyContent: 'center',
                             alignItems: 'center'
                        }}>
                            <Typography variant="body2" component="div" style={{ width: "30px" }}>{amount}</Typography>
                        </Box>
                    </Box>
                )}
                {bulletPoints && (
                    <ul style={{height:"280px"}}>
                        {bulletPoints.map((point, index) => (
                            <li key={index} style={{ textAlign: "start",fontSize:"14px" }}>{point}</li>
                        ))}
                    </ul>
                )}
                {showButton && buttonText && (
                    <Box mt={2} style={{ textAlign: 'center', marginBottom: "30px" ,marginTop:"0"}}>
                        <Button variant="contained" style={{ textTransform: "none", background: themestyle.colors.navbarcolor, 
                            borderRadius: "100px", width: "190px", height: "41px" }}>
                            {buttonText}
                        </Button>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};
export default PhoenixCard;