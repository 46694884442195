import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Box,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
  SelectChangeEvent,
} from "@mui/material";
import { Link } from "react-router-dom";
import { FOOTER_MESSAGES } from "../const";
import PheonixFooterProps from "../interfaces/PheonixFooterProps";
import pheonixlogo from "../assets/pheonixlogo.png";
import "./style.css";
import { languages } from "../pages/Productdata";
import Facebookicon from "../assets/Facebook 1.png";
import instagramicon from "../assets/LinkedIn 1.png";
import youtubeicon from "../assets/YouTube 1.png"
import Languages1 from "../interfaces/LanguageProps";
import themestyle from "../pages/theme";
const Footer: React.FC<PheonixFooterProps> = ({ style }) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [currentYear, setCurrentYear] = useState<number>(
    new Date().getFullYear()
  );
  const [selectedLanguage, setSelectedLanguage] = useState<string>('English');
  const handleChange = (event: SelectChangeEvent<string>) => {
    setSelectedLanguage(event.target.value as string);
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 60000);
    return () => clearInterval(intervalId);
  }, []);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const renderSection = (title: string, links: { label: string; to: string }[]) => (
    <Box
      sx={{
        marginBottom: 4,
        textAlign: isSmallScreen ? "center" : "left",
        marginLeft: isSmallScreen ? 0 : 4,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontWeight: 700,
          fontSize: "20px",
          color: "#FFFFFF",
          marginBottom: "10px",
        }}
      >
        {title}
      </Typography>
      {links.map((link, index) => (
        <Link
          key={index}
          to={link.to}
          style={{ color: "inherit" }}
          onClick={scrollToTop}
          className="footer-link"
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              fontFamily: "Poppins",
              marginBottom: "5px",
            }}
          >
            {link.label}
          </Typography>
        </Link>
      ))}
    </Box>
  );
 return (
    <div style={{ backgroundColor: "black" }}>
      <footer
        style={{
          left: 0,
          bottom: 0,
          width: "100%",
          height: isSmallScreen ? "auto" : "335px",
          padding: "30px",
          backgroundColor: "#000000",
          opacity: "85%",
          color: "#fff",
          fontFamily: "Poppins",
        }}
      >
        <Container sx={{ paddingTop: "30px", paddingBottom: "30px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              justifyContent: isSmallScreen ? "center" : "space-between",
              alignItems: isSmallScreen ? "center" : "flex-start",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: isSmallScreen ? "100%" : "auto",
                marginBottom: isSmallScreen ? "20px" : 0,
                justifyContent: isSmallScreen ? "center" : "flex-start",
              }}
            >
              <Box component="img" src={pheonixlogo} alt="logo" sx={{ maxWidth: "150px" }} />
            </Box>
            {renderSection(FOOTER_MESSAGES.PRODUCT, [
              { label: FOOTER_MESSAGES.FEATURES, to: "#" },
              { label: FOOTER_MESSAGES.PRICING, to: "#" },
            ])}
            {renderSection(FOOTER_MESSAGES.RESOURCES, [
              { label: FOOTER_MESSAGES.BLOG, to: "#" },
              { label: FOOTER_MESSAGES.USER_GUIDES, to: "#" },
              { label: FOOTER_MESSAGES.WEB, to: "#" },
            ])}
            {renderSection(FOOTER_MESSAGES.COMPANY, [
              { label: FOOTER_MESSAGES.ABOUT, to: "#" },
              { label: FOOTER_MESSAGES.JOINUS, to: "#" },
            ])}

          </Box>
          <hr />
          <div
            style={{
              textAlign: "center",
              fontFamily: "Poppins",
              color: "rgba(255, 255, 255, 1)",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "25.56px",
              marginTop: "10px",
              paddingBottom: "10px",
            }}
          >
            &copy; {currentYear} <a href={FOOTER_MESSAGES.Pheonix_link} target="_blank"
              rel="noopener noreferrer" style={{ textDecoration: "none", color: themestyle.colors.white }}><span>{FOOTER_MESSAGES.FOOTER_TXT}</span></a>
            <a
              href={FOOTER_MESSAGES.FB}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Facebook"
              style={{ margin: "5px" }}
            >
              <img
                src={Facebookicon}
                alt="facebook"
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </a>
            <a
              href={FOOTER_MESSAGES.Linkdin}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
              style={{ margin: "0 5px" }}
            >
              <img
                src={instagramicon}
                alt="instagram"
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </a>
            <a
              href={FOOTER_MESSAGES.Youtube}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="YouTube"
              style={{ margin: "0 5px" }}
            >
              <img
                src={youtubeicon}
                alt="youtube"
                style={{
                  width: "24px",
                  height: "24px",
                }}
              />
            </a>
          </div>
        </Container>
      </footer>
    </div>
  );
};
export default Footer;