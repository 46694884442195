import React, { useState, MouseEvent } from 'react';
import { AppBar, Toolbar, Button, IconButton, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme, Box, CssBaseline, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import pheonixlogo from "../assets/logo.png";
import { TEXT_MESSAGES } from '../const';
import { NavbarProps1 } from '../interfaces/Navbar';
import CloseIcon from '@mui/icons-material/Close';
import themestyle from '../pages/theme';

const Navbar: React.FC<NavbarProps1> = ({ sections, activeTab, setActiveTab }) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const tabs = [
        { label: 'About', ref: sections.aboutRef },
        { label: 'Feature', ref: sections.featureRef },
        { label: 'Pricing', ref: sections.pricingRef },
        { label: 'Contact', ref: sections.contactRef }
    ];
 const handleTabClick = (index: number, ref: React.RefObject<HTMLDivElement>) => {
        setActiveTab(index);
        ref.current?.scrollIntoView({ behavior: 'smooth' });
    };
     const toggleDrawer = (open: boolean) => () => {
        setDrawerOpen(open);
    };
     const callToAction = {
        label: TEXT_MESSAGES.SIGNIN,
    };
     return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="static" sx={{ background: "linear-gradient(275.81deg, #3326C3 2.78%, #DE0093 95.23%)", justifyContent: "space-around", height: isMobile ? "" : "96px" }}>
                <Toolbar>
                    <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                        <div>
                            <img src={pheonixlogo} alt="pheonixlogo" style={{ height: isMobile ? 40 : "81px", width: isMobile ? "" : "101px" }} />
                        </div>
                        {!isMobile && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
                                {tabs.map((tab, index) => (
                                    <Button
                                        key={index}
                                        color="inherit"
                                        onClick={() => handleTabClick(index, tab.ref)}
                                        sx={{
                                            borderBottom: activeTab === index ? '2px solid white' : 'none',
                                            borderRadius: '0px',
                                            marginRight: '16px', textTransform: "none", fontFamily: "Poppins", fontSize: "16px", fontWeight: activeTab === index ? "bold" : 600
                                        }}
                                    >
                                        {tab.label}
                                    </Button>
                                ))}
                            </Box>
                        )}
                    </div>
                    {isMobile ? (
             <>
             <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
                 <MenuIcon />
             </IconButton>
             <Menu
                 anchorEl={anchorEl}
                 open={Boolean(anchorEl)}
                 onClose={handleMenuClose}
                 anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                 transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                 sx={{ width: '285px',marginTop:"30px",padding:"30px" }}
             >
                 {tabs.map((tab, index) => (
                     <MenuItem key={index} onClick={() => { handleTabClick(index, tab.ref); handleMenuClose(); }} >
                         <Box display="flex" justifyContent="space-between" alignItems="center" width="133px" marginLeft="30px">
                             <ListItemText primary={tab.label} style={{color:themestyle.colors.textPrimary}} />
                             {index === 0 && (
                                 <IconButton edge="end" color="inherit" aria-label="close" onClick={handleMenuClose}>
                                     <CloseIcon />
                                 </IconButton>
                             )}
                         </Box>
                     </MenuItem>
                 ))}
                 <MenuItem onClick={handleMenuClose}>
                 <Button color="inherit" style={{
                            borderRadius: "100px", width: "119px", fontFamily: "Poppins",height:"35px", marginRight: isMobile ? "" : "40px",marginLeft:"25px",
                            textTransform: "none", marginBottom:"30px",fontSize: "14px", backgroundColor: themestyle.colors.purple, color: themestyle.colors.white, fontWeight: 600
                        }}>
                            {callToAction.label}
                        </Button>
                 </MenuItem>
             </Menu>
         </>
            ) : (
                        <Button color="inherit" style={{
                            borderRadius: "100px", width: "134px", fontFamily: "Poppins", marginRight: isMobile ? "" : "40px",
                            textTransform: "none", fontSize: "18px", backgroundColor: "#EFE2FF", color: "#3926C2", fontWeight: 600
                        }}>
                            {callToAction.label}
                        </Button>
                    )}
                </Toolbar>
            </AppBar>
        </Box>
    );
};
export default Navbar;