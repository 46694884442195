import React from "react";
import PheonixTableComponentProps from "../interfaces/PheonixTablecomponentProps";
import { approveIcon, rejectIcon } from "../pages/Productdata";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  useMediaQuery,
  useTheme,
  Box,
  Typography
} from "@mui/material";
import { TEXT_MESSAGES } from "../const";

const renderIcon = (isApproved: boolean) => isApproved ? approveIcon : rejectIcon;
const PheonixTable: React.FC<PheonixTableComponentProps> = ({ data }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileOrTablet = isMobile || isTablet;
return (
    <TableContainer
      sx={{
        width: isMobile ? "100%" : isTablet ? "210%" : "145%",
        marginTop: "30px",
        overflowX: "auto",
      }}
    >
      {!isMobile && (
        <Table
          sx={{
            minWidth: isMobile ? '300px' : '600px',
            borderBottom: '0.5px solid #651CB5',
            borderRight: '1px solid #651CB5',
            '& .MuiTableCell-root': {
              padding: '8px',
              borderBottom: 'none',
              borderRight: '1px solid #651CB5',
              borderLeft: '1px solid #651CB5',
            },
            '& .MuiTableCell-head': {
              fontWeight: 'bold',
              borderBottom: '1px solid #651CB5',
            },
            '& .MuiTableRow-root': {
              '&:first-of-type .MuiTableCell-root': {
                borderTop: '1px solid #651CB5',
              },
            }
          }}
          aria-label="plan features table"
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center" sx={{ backgroundColor: "#F6EFFF" }}>{TEXT_MESSAGES.BAIC}</TableCell>
              <TableCell align="center">{TEXT_MESSAGES.PRO}</TableCell>
              <TableCell align="center" sx={{ backgroundColor: "#F6EFFF" }}>{TEXT_MESSAGES.Premium}</TableCell>
              <TableCell align="center">{TEXT_MESSAGES.Custom}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row"
                sx={{
                  fontWeight: 'bold', textAlign: "center", backgroundColor: "#F6EFFF",
                  borderBottom: '1px solid rgba(224, 224, 224, 1)'
                }}>
                {TEXT_MESSAGES.FEATURES}
              </TableCell>
              <TableCell sx={{ backgroundColor: "#F6EFFF" }}></TableCell>
              <TableCell></TableCell>
              <TableCell sx={{ backgroundColor: "#F6EFFF" }}></TableCell>
              <TableCell></TableCell>
            </TableRow>
            {data.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {row.feature}
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: "#F6EFFF" }}>
                  {renderIcon(row.basic)}
                </TableCell>
                <TableCell align="center">
                  {renderIcon(row.pro)}
                </TableCell>
                <TableCell align="center" sx={{ backgroundColor: "#F6EFFF" }}>
                  {renderIcon(row.premium)}
                </TableCell>
                <TableCell align="center">
                  {renderIcon(row.custom)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}

      {isMobile && (
        <>
          <Table  sx={{textAlign:"center",
            minWidth: isMobile ? '280px' : '600px',
            borderBottom: '0.5px solid #651CB5',
            borderRight: '1px solid #651CB5',
            '& .MuiTableCell-root': {
              padding: '8px',
              borderBottom: 'none',
              borderRight: '1px solid #651CB5',
              borderLeft: '1px solid #651CB5',
            },
            '& .MuiTableCell-head': {
              fontWeight: 'bold',
              borderBottom: '1px solid #651CB5',
            },
            '& .MuiTableRow-root': {
              '&:first-of-type .MuiTableCell-root': {
                borderTop: '1px solid #651CB5',
              },
            }
          }}>
            <TableHead>
              <TableRow>
                <TableCell align="center" sx={{ fontWeight: 'bold', backgroundColor: "#F6EFFF",borderBottom: '0.5px solid #651CB5', }}>{TEXT_MESSAGES.FEATURES}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index} >
                  <TableCell component="th" scope="row" sx={{ textAlign: "left" }}>
                    {row.feature}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

          <Table sx={{textAlign:"center",
            minWidth: isMobile ? '280px' : '600px',
            borderBottom: '0.5px solid #651CB5',
            borderRight: '1px solid #651CB5',
            '& .MuiTableCell-root': {
              padding: '8px',
              borderBottom: 'none',
              borderRight: '1px solid #651CB5',
              borderLeft: '1px solid #651CB5',
            },
            '& .MuiTableCell-head': {
              fontWeight: 'bold',
              borderBottom: '1px solid #651CB5',
            },
            '& .MuiTableRow-root': {
              '&:first-of-type .MuiTableCell-root': {
                borderTop: '1px solid #651CB5',
              },
            }
          }}>
            <TableHead >
              <TableRow >
                <TableCell align="center" sx={{ backgroundColor: "#F6EFFF",borderBottom: '0.5px solid #651CB5' }}>{TEXT_MESSAGES.BAIC}</TableCell>
                <TableCell align="center" style={{borderBottom: '0.5px solid #651CB5'}}>{TEXT_MESSAGES.PRO}</TableCell>
                <TableCell align="center" sx={{ backgroundColor: "#F6EFFF",borderBottom: '0.5px solid #651CB5' }}>{TEXT_MESSAGES.Premium}</TableCell>
                <TableCell align="center" style={{borderBottom: '0.5px solid #651CB5'}}>{TEXT_MESSAGES.Custom}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center" sx={{ backgroundColor: "#F6EFFF", border: 'none' }}>
                    {renderIcon(row.basic)}
                  </TableCell>
                  <TableCell align="center" sx={{ border: 'none' }}>
                    {renderIcon(row.pro)}
                  </TableCell>
                  <TableCell align="center" sx={{ backgroundColor: "#F6EFFF", border: 'none' }}>
                    {renderIcon(row.premium)}
                  </TableCell>
                  <TableCell align="center" sx={{ border: 'none' }}>
                    {renderIcon(row.custom)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </TableContainer>
  );
}

export default PheonixTable;
