export const  TEXT_MESSAGES = {
PRODUCT_intro: "Product Intro",
PRODUCTINTRI_TITLE:"The only reason we are starting with problem is to trigger an investment mindset BEFORE you even show your product. This will remind your visitors of how painful the problem is and why they want to fix it asap.The only reason we are starting with problem is to trigger an investment mindset BEFORE you even show your product. This will remind your visitors of how painful the problem is and why they want to fix it asap.",
PRODUCTINTRO_TITLE2:"Show them they can try your product instead",
card_title:"Choose a product",
card_description:"the quick fox jumps over the lazy dog",
card_title2:"Set For Organisation",
card_title3:"100% Business",
OUR_CLIENT:"Our Client",
DESCRIPTION:"This section is NOT about explaining the product as best as possible, but about creating curiosity to keep scrolling.",
HOW_TO:"How To Achieve Without?",
SHOW:"Show your Visitors",
PROD_TITLE:"Happy Clients",
PROD_TITLE1:"Our products",
PROD_TITLE2:"Our services",
FEATURES:"Features",
PLAN_FEATURES:"Plan Features",
PLAN_FEATURES1:"Plan Features continued...",
SIGNIN:"Sign-in",
BAIC:"Basic",
PRO:"Pro",
Premium:"Premium",
Custom:"Custom",
PRICING:"Pricing",
Monthly:"Monthly",
yearly:"Yearly",
join:"Join the 300+ companies that are leveraging our platform",
join1:"Our expert will assist you with the best solutions for your company",
PRICING_TITLE:"Officially, the exercise requires the consent of the elite.",
PRICING_TITLE1:"Officia exercitation quis voluptate elit consequat nostrud",
FEATURES_TEXT:"The only reason we are starting with problem is to trigger an investment mindset BEFORE you even show your product. This will remind your visitors of how painful the problem is and why they want to fix it asap."
}
export const  FOOTER_MESSAGES = {
PRODUCT:"Product",
RESOURCES:"Resources",
COMPANY:"Company",
LANGUAGE:"Language",
FEATURES:"Features",
PRICING:"Pricing",
BLOG:"Blog",
USER_GUIDES:"User guides",
WEB:"Webinars",
ABOUT:"About",
JOINUS:"Join us",
FOOTER_TXT:"PheonixSolutions Team",
PRIVACY:" . Privacy",
TERMS:" . Terms",
SITE_MAP:" . Sitemap",
Linkdin:"https://www.linkedin.com/company/pheonixsolutions",
Youtube:"https://youtube.com",
FB:"https://www.facebook.com/pheonixsolutionss",
Pheonix_link:"https://pheonixsolutions.com/"
}
export const Form_MESSAges = {
    button_txt:"Request a call back",
    FULL_NAME:"Full Name",
    Phone_no:"Phone Number",
    Email:"Email",
    company_name:"Company Name",
    NAME_ERR:'Full Name is required',
    PHONE_ERR:'Phone Number is required',
    PHONE_ERR1:'Phone Number is not valid',
    EMAIL_ERR:'Email is required',
    EMAIL_ERR1:"Email is not valid",
    COMPANY_ERR:"Company Name is required",
    Full_name:"fullName",
    Phone_number:"PhoneNumber",
    Email1:"email",
    Company_name:"CompanyName",
    Fullname_plc:"Enter your name",
    Phone_plc:"Enter your phone number",
    email_plc:"Enter your email address",
    company_plc:"Enter your company name"
}