import React from 'react';
import Slider from 'react-slick';
import { useMediaQuery, Grid } from '@mui/material';
import styled from 'styled-components';
import themestyle from '../pages/theme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { imageUrls } from '../pages/Productdata';

const CustomDot = styled.div`
    text-align: center;
    font-size: 34px; 
    color: gray; 
    cursor: pointer;
    margin: 0 10px; 
    &:hover {
        color: ${themestyle.colors.purple}; 
    }
    &.slick-active {
        color: ${themestyle.colors.purple}; 
    }
    &.slick-disabled {
        opacity: 0.5; 
        cursor: default; 
    }
`;

const ImageCarousel = () => {
    const isMobile = useMediaQuery('(max-width:600px)');

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3, 
        slidesToScroll: 3, 
        dotsClass: 'slick-dots slick-thumb',
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
        ],
        customPaging: (i: number) => (
            <CustomDot className={i === 0 ? 'slick-disabled' : ''}>{i < imageUrls.length ? '•' : null}</CustomDot>
        ),
    };

    return (
        <Grid container spacing={2} style={{ justifyContent: 'center', marginTop: themestyle.spacing.medium }}>
            <style>
                {`
                .slick-dots {
                    bottom: 10px;
                }
                `}
            </style>
            {isMobile ? (
                <Grid item xs={12}>
                    <Slider {...settings}>
                        {imageUrls.map((img, index) => (
                            <div key={index} style={{ textAlign: 'center' }}>
                                <img 
                                    src={img} 
                                    alt={`Client ${index + 1}`} 
                                    style={{
                                        width: index === 5 ? '130px' : '150px', 
                                        height: index === 5 ? '69px' : 'auto',
                                        marginBottom: '40px',
                                    }} 
                                />
                            </div>
                        ))}
                    </Slider>
                </Grid>
            ) : (
                imageUrls.map((img, index) => (
                    <Grid item key={index} xs={6} sm={4} md={2} style={{ textAlign: 'center' }}>
                        <img src={img} alt={`Client ${index + 1}`} style={{ width: isMobile ? '153px' : '', height: 'auto' }} />
                    </Grid>
                ))
            )}
        </Grid>
    );
};
export default ImageCarousel;