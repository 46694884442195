import React from 'react';
import { Box, Typography } from '@mui/material';
import PheonixBoxProps from '../interfaces/PheonixBoxProps';

const PheonixBox: React.FC<PheonixBoxProps> = ({ icon, title, count }) => {
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: "10px" }}>
   <Box style={{ display: 'flex', alignItems: 'center', textAlign: 'center',marginBottom:"20px" }}>
        <Box component="img" src={icon} alt={title} style={{ marginRight: '20px' }} />
        <Typography variant="h6" style={{ color: '#FFFFFF',fontSize:"30px",fontWeight:500 }}>{title}</Typography>
      </Box>
      <Typography variant="h4" style={{ color: '#FFFFFF', fontWeight: 'bold' }}>{count}</Typography>
    </Box>
  );
};

export default PheonixBox;