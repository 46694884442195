import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { styled } from '@mui/material/styles';
import themestyle from '../pages/theme';
import PheonixToggleSwitchProps from '../interfaces/PheonixToggleProps';
import { TEXT_MESSAGES } from '../const';
const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    '&.Mui-selected': {
        color: 'black',
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        width: '130px',
        border: 'none',
    },
    '&.MuiToggleButton-root': {
        color: themestyle.colors.textPrimary,
        margin: "3px",
        border: 'none',
       
        '&:hover': {
            backgroundColor: 'inherit',
            borderRadius: '20px',
        },
    },
    '&.Mui-selected:hover': {
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
    },
}));

const PheonixToggleSwitch: React.FC<PheonixToggleSwitchProps> = ({ selectedOption, handleToggle }) => {
    return (
        <ToggleButtonGroup
            value={selectedOption}
            exclusive
            onChange={handleToggle}
            aria-label="text alignment"
            style={{ backgroundColor: themestyle.colors.lightpink, borderRadius: '20px',padding:"2px", border: 'none',textTransform:"none",width:"240px" }}
        >
            <StyledToggleButton value="monthly" aria-label="monthly" style={{textTransform:"none",marginRight:"20px",marginLeft: selectedOption === "yearly" ? "20px" : "10px" }} >
                {TEXT_MESSAGES.Monthly}
            </StyledToggleButton>
            <StyledToggleButton value="yearly" aria-label="yearly" style={{textTransform:"none",marginLeft:"20px",marginRight:selectedOption === "monthly" ? "20px" : "10px" }}>
               {TEXT_MESSAGES.yearly}
            </StyledToggleButton>
        </ToggleButtonGroup>
    );
};
export default PheonixToggleSwitch;