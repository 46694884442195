import img1 from "../assets/img1.png";
import img2 from "../assets/img2.png";
import img3 from "../assets/img3.png";
import img4 from "../assets/img4.png";
import img5 from "../assets/img5.png";
import img6 from "../assets/img6.png";
import contacticon from "../assets/contacticon.png"
import { TEXT_MESSAGES } from '../const';
import happyicon from "../assets/happyicon.png"
import vector2 from "../assets/Vector2.png";
import vector3 from "../assets/Vector3.png";
import approveicon from "../assets/Check icon.png";
import closeicon from "../assets/closeicon.png";
import PheonixTableProps from "../interfaces/PheonixTableProps";
import { PhoenixCardProps } from "../interfaces/PheonixCardProps";
export const cardData = [
  {
    title: TEXT_MESSAGES.card_title,
    mediaUrl: contacticon,
    description: TEXT_MESSAGES.card_description,
  },
  {
    title: TEXT_MESSAGES.card_title2,
    mediaUrl: contacticon,
    description: TEXT_MESSAGES.card_description,
  },
  {
    title: TEXT_MESSAGES.card_title3,
    mediaUrl: contacticon,
    description: TEXT_MESSAGES.card_description,
  }
];
export const imageUrls = [img1, img2, img3, img4, img5, img6];
export const productcount = [
  {
    icon: happyicon,
    title: TEXT_MESSAGES.PROD_TITLE,
    count: "128",
  },
  {
    icon: vector2,
    title: TEXT_MESSAGES.PROD_TITLE1,
    count: "24",
  },
  {
    icon: vector3,
    title: TEXT_MESSAGES.PROD_TITLE2,
    count: "78",
  }
]
export const languages = [
  { "value": "English", "label": "English" },
  { "value": "Spanish", "label": "Spanish" },
  { "value": "French", "label": "French" }
]
export const planFeatures: PheonixTableProps[] =
  [
    { feature: 'User/Employee Management', basic: true, pro: true, premium: true, custom: true },
    { feature: 'Login/Portal Management', basic: true, pro: true, premium: true, custom: true },
    { feature: 'Attendance Management', basic: true, pro: true, premium: true, custom: true },
    { feature: 'Report Management', basic: true, pro: true, premium: true, custom: true },
    { feature: 'Leave Management', basic: true, pro: true, premium: true, custom: true },
    { feature: 'Shift Management', basic: true, pro: true, premium: true, custom: true },
    { feature: 'Activity Management', basic: true, pro: true, premium: true, custom: true },
    { feature: 'Holiday Management', basic: true, pro: true, premium: true, custom: true },
    { feature: 'Documentation Management', basic: true, pro: true, premium: true, custom: true },
    { feature: 'Event Management', basic: true, pro: true, premium: true, custom: true },
    { feature: 'Hierarchy Management', basic: true, pro: true, premium: true, custom: true },
    { feature: 'Roles Management', basic: false, pro: true, premium: true, custom: true },
    { feature: 'Roles Management(Customization)', basic: false, pro: true, premium: true, custom: true },
    { feature: 'Team Management', basic: false, pro: true, premium: true, custom: true },
    { feature: 'Asset Management', basic: false, pro: false, premium: true, custom: true },
    { feature: 'Expenses Management', basic: false, pro: false, premium: true, custom: true },
    { feature: 'Chat Management', basic: false, pro: false, premium: true, custom: true },
    { feature: 'Gallery Management', basic: false, pro: false, premium: true, custom: true },
    { feature: 'Integration Applications(Msteam,Slack)', basic: false, pro: false, premium: true, custom: true },
    { feature: 'Payslip Management', basic: false, pro: false, premium: false, custom: true },
    { feature: 'Premium Customer Support', basic: false, pro: false, premium: false, custom: true },
  ];
export const approveIcon = <img src={approveicon} alt="Approved" style={{ width: '20px', height: '20px' }} />;
export const rejectIcon = <img src={closeicon} alt="Rejected" style={{ width: '20px', height: '20px' }} />;
export const monthlyCards: PhoenixCardProps[] = [
  {
    title: 'Basic', description1: '12 months access', showButton: true,
    buttonText: 'Check Now',
    amount:"Free",
    bulletPoints: [
      'Number of user: 10',
      'Reports',
      'Third part integration (slack, Steam)',
      'Financial Year management',
      'Leave ManagementBullet',
      'Shift management',
      'Payslip management'
    ]
  },
  {
    title: 'Pro', description1: '18 months access', showButton: true,
    buttonText: 'Get Started',
    amount:"$15 User",
    bulletPoints: [
      'Number of user: 25',
      'Reports',
      'Chat facility',
      'Third part integration (slack, Steam)',
      'Financial Year management',
      'Leave Management',
      'Shift management',
      'Payslip management'
    ]
  },
  {
    title: 'Premium', description1: '18 months access', showButton: true,
    buttonText: 'Get Started',
    amount:"$20 User",
    bulletPoints: [
      'Number of user: 50',
      'Report',
      'Chat facility',
      'Third part integration (slack, Steam, Matter most)',
      'Financial Year management',
      'Leave Management',
      'Email Notifications',
      'Shift management',
      'Payslip management',
      'Customer Support'
    ]
  },
  {
    title: 'Custom', description1: `let's talk`, showButton: true,
    buttonText: 'Book a call',
    amount:"$25 User",
    bulletPoints: [
      'Number of user: 100',
      'Report',
      'Chat facility',
      'Third part integration (slack, Steam, Matter most)',
      'Financial Year management',
      'Leave Management',
      'Email Notifications',
      'Shift management',
      'Payslip management',
      'Customer Support'
    ]
  },
];

export const yearlyCards: PhoenixCardProps[] = [
  {
    title: 'Basic', description1: '1 year access', showButton: true,
    buttonText: 'Check Now',
    amount:"Free",
    bulletPoints: [
      'Number of user: 10',
      'Reports',
      'Third part integration (slack, Steam)',
      'Financial management',
      'Leave ManagementBullet',
      'Shift management',
      'Payslip management'
    ]
  },
  {
    title: 'Pro', description1: '1 year access', showButton: true,
    buttonText: 'Get Started',
    bulletPoints: [
      'Number of user: 10',
      'Reports',
      'Third part integration (slack, Steam)',
      'Financial management',
      'Leave ManagementBullet',
      'Shift management',
      'Payslip management'
    ]
  },
  {
    title: 'Premium', description1: '2 years access', showButton: true,
    buttonText: 'Check Now',
    amount:"$15 User",
    bulletPoints: [
      'Number of user: 10',
      'Reports',
      'Third part integration (slack, Steam)',
      'Financial management',
      'Leave ManagementBullet',
      'Shift management',
      'Payslip management'
    ]
  },
  {
    title: 'Custom', description1: '1 year access', showButton: true,
    buttonText: 'Get Started',
    amount:"$15 User",
    bulletPoints: [
      'Number of user: 10',
      'Reports',
      'Third part integration (slack, Steam)',
      'Financial management',
      'Leave ManagementBullet',
      'Shift management',
      'Payslip management'
    ]
  },
];