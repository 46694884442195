import React, { useState, useRef, useEffect } from 'react';
import {
  Container, Grid, Typography, useMediaQuery, useTheme, Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@mui/material';
import PhoenixCard from "../components/PheonixCard"
import Productvideo from "../assets/Product video.png";
import PhoenixButton from '../components/PhoenixButton';
import { TEXT_MESSAGES } from '../const';
import { cardData, imageUrls, productcount, planFeatures } from './Productdata';
import PheonixBox from './PheonixBox';
import themestyle from './theme';
import amico from "../assets/amico.png";
import closeicon from "../assets/closeicon.png";
import approveicon from "../assets/Check icon.png";
import PheonixTable from '../components/PheonixTable';
import amico2 from "../assets/OBJECTS.png";
import { PhoenixCardProps } from '../interfaces/PheonixCardProps';
import PheonixToggleSwitch from '../components/PheonixToggleSwitch';
import { monthlyCards, yearlyCards } from './Productdata';
import Formdata from '../components/Formdata';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { AboutProps } from '../interfaces/LayoutProps';
import ImageCarousel from '../components/carouselcomponent';
const About: React.FC<AboutProps> = ({ sections }) => {
  const firstTableData = planFeatures.slice(0, 11);
  const secondTableData = planFeatures.slice(11);
  const contactRef = sections.contactRef;
  const handleActionClick = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };
  const [selectedOption, setSelectedOption] = useState<string>('monthly');
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  const handleToggle = (event: React.MouseEvent<HTMLElement>, newOption: string) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
    }
  };
  const cardsToDisplay = selectedOption === 'monthly' ? monthlyCards : yearlyCards;
  const theme = useTheme();
  
  const videoUrl = "https://www.youtube.com/embed/tgbNymZ7vqY";
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobileOrTablet = isMobile || isSmallScreen;
return (
    <div style={{ marginBottom: isSmallScreen ? '0px' : "40px", textAlign: 'center' }}>
     <Grid
      container
      spacing={2}
      style={{
        padding: isSmallScreen ? '48px 32px' : '96px 64px',
        backgroundColor: themestyle.colors.background,
        justifyContent: 'center', 
        textAlign: 'center' 
      }}
    >
      <Grid item xs={12} sm={6} md={4}>
        <img
          src={Productvideo}
          alt="Product Video"
          style={{
            width: '100%',
            height: 'auto',
            maxWidth: isSmallScreen ? '80%' : '600px',
            margin: '0 auto' 
          }}
          loading="lazy"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={6}>
        <Typography
          variant="h6"
          style={{
            color: themestyle.colors.textPrimary,
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: themestyle.fontSizes.medium,
            marginBottom: themestyle.spacing.medium
          }}
        >
          {TEXT_MESSAGES.SHOW}
        </Typography>
        <Typography
          variant="h4"
          style={{
            fontFamily: 'Poppins',
            fontWeight: 600,
            fontSize: isSmallScreen ? themestyle.fontSizes.large : themestyle.fontSizes.xlarge,
            color: themestyle.colors.primary,
            marginBottom: themestyle.spacing.medium
          }}
        >
          {TEXT_MESSAGES.HOW_TO}
        </Typography>
        <Typography
          style={{
            color: themestyle.colors.textSecondary,
            fontSize: themestyle.fontSizes.small,
            fontWeight: 600
          }}
        >
          {TEXT_MESSAGES.DESCRIPTION}
        </Typography>

        <Grid container justifyContent="center">
          <PhoenixButton
            label="Contact us"
            onClick={handleActionClick}
            style={{
              color: themestyle.colors.white,textTransform:"none",fontWeight:600,fontSize:"18px",
              borderRadius: themestyle.borderRadius,
              marginTop: themestyle.spacing.large,
              width: '167px',
              height: '61px',
              background: `linear-gradient(275.81deg, ${themestyle.colors.primary} 2.78%, ${themestyle.colors.secondary} 95.23%)`,
            }}
          />
        </Grid>
      </Grid>
    </Grid>
      <Grid container
        style={{
          textAlign: 'center', justifyContent: 'center',
          marginTop: themestyle.spacing.medium
        }}>
        <Typography
          variant="h3"
          style={{
            fontSize: isMobile ? themestyle.fontSizes.large : themestyle.fontSizes.xxlarge,
            fontWeight: 600,
            fontFamily: 'Poppins',
            color: themestyle.colors.primary,
            marginBottom: themestyle.spacing.medium,
          }}
        >
          {TEXT_MESSAGES.OUR_CLIENT}
        </Typography>
      </Grid>
      
      <ImageCarousel />
  
      <Grid
        container
        spacing={2}
        style={{
          textAlign: 'center',
          justifyContent: 'center',
          marginTop: themestyle.spacing.large,
          background: `linear-gradient(275.81deg, ${themestyle.colors.primary} 2.78%, ${themestyle.colors.secondary} 95.23%)`,
        }}
      >
        <Grid item style={{ padding: isMobile ? "23px 34px" : "23px 104px" }}>
          <Typography
            variant="h4"
            style={{
              fontFamily: 'Poppins',
              fontWeight: 600,
              fontSize: isSmallScreen ? themestyle.fontSizes.large : themestyle.fontSizes.xlarge,
              color: themestyle.colors.white,
              marginBottom: themestyle.spacing.medium,
              textAlign: "center",
              marginTop: themestyle.spacing.xlarge
            }}
          >
            {TEXT_MESSAGES.PRODUCT_intro}
          </Typography>
          <Typography
            variant="h6"
            style={{
              color: themestyle.colors.white,
              fontSize: themestyle.fontSizes.small,
              fontWeight: 600,
              marginBottom: themestyle.spacing.large,
              width: "100%",
              lineHeight: "27px",
              fontFamily: "Poppins"
            }}
          >
            {TEXT_MESSAGES.PRODUCTINTRI_TITLE}
          </Typography>
          <Typography
            style={{
              color: themestyle.colors.white,
              fontFamily: 'Poppins',
              fontWeight: 600,
              lineHeight:"27px",
              fontSize: themestyle.fontSizes.small,
              marginBottom: themestyle.spacing.medium,
              textAlign: "center"
            }}
          >
            {TEXT_MESSAGES.PRODUCTINTRO_TITLE2}
          </Typography>
        </Grid>
        <Grid
          container
          spacing={0}
          justifyContent="center"
          style={{ textAlign: "center", margin: "0 auto", marginLeft: "20px" }}
        >
          {cardData.map((card, index) => (
            <Grid item key={index} style={{ margin: isMobile ? themestyle.spacing.small + ' 20px' : '0 25px 0 25px' }}>
              <PhoenixCard
                title={card.title}
                mediaUrl={card.mediaUrl}
                description={card.description}
              />
            </Grid>
          ))}
        </Grid>
        <Grid
          container
          spacing={0}
          justifyContent="center"
          style={{ textAlign: "center", margin: themestyle.spacing.large + ' 0' }}
        >
          {productcount.map((product, index) => (
            <Grid
              item
              key={index}
              xs={12}
              sm={4}
              md={4}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <PheonixBox
                icon={product.icon}
                title={product.title}
                count={product.count}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container ref={sections.featureRef}
        style={{
          textAlign: 'center', justifyContent: 'center',
          marginTop: themestyle.spacing.medium
        }}>
        <Typography
          variant="h3"
          style={{
            fontSize: isMobile ? themestyle.fontSizes.large : themestyle.fontSizes.xxlarge,
            fontWeight: 600,
            fontFamily: 'Poppins',
            color: themestyle.colors.purple,
            marginBottom: themestyle.spacing.medium,
          }}
        >
          {TEXT_MESSAGES.FEATURES}
        </Typography>
        <Typography style={{
          fontSize: themestyle.fontSizes.small,
          fontWeight: 600, textAlign: "center", padding: "0 96px 0px 64px ", lineHeight:isMobile ? "34px": "27px",
          fontFamily: 'Poppins',
          color: themestyle.colors.fcolor,
          marginBottom: themestyle.spacing.medium,
        }}>{TEXT_MESSAGES.FEATURES_TEXT}</Typography>
      </Grid>
      <Grid container
        spacing={2}
        style={{
          padding: isMobile ? "40px" : "80px"
        }}
      >
        <Grid item xs={12} sm={6} md={4} style={{ textAlign: 'start', height: isMobile ? "" : "572px", gap: "16px", marginRight: "180px" }}>
          <Typography style={{ fontSize: "40px", fontWeight: 600, lineHeight: "48px", fontFamily: "Poppins", color: "#000000" }}>{TEXT_MESSAGES.PLAN_FEATURES}</Typography>
          <PheonixTable data={firstTableData} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ textAlign: 'center' }}>
          <img src={amico} alt='amico' style={{
            width: isMobile ? "274px" : '574px',
            height: isMobile ? "286px" : '594px',textAlign:"center",
            maxWidth: isSmallScreen ? '80%' : '574px',marginTop:isSmallScreen?"60px":"40px"
          }} />
        </Grid>
      </Grid>
      <Grid container
      spacing={2}
      style={{
        padding: isMobileOrTablet ? "40px" : "80px",
        marginBottom: "40px"
      }}
    >
      {isMobile? (
        <>
          <Grid item xs={12} style={{ textAlign: 'start', gap: "16px" }}>
            <Typography style={{
              fontSize: "40px", fontWeight: 600, lineHeight: "48px", fontFamily: "Poppins",
              color: "#000000"
            }}>{TEXT_MESSAGES.PLAN_FEATURES1}</Typography>
            <PheonixTable data={secondTableData} />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <img src={amico2} alt='amico' style={{
              width: "274px",
              height: "286px",
              marginTop: "20px"
            }} />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} sm={6} md={4} style={{ textAlign: 'center', marginRight: "180px" }}>
            <img src={amico2} alt='amico' style={{
              width: '574px',
              height: '343px',
              marginTop: "20vh"
            }} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} style={{ textAlign: 'start', height: "572px", gap: "16px" }}>
            <Typography style={{
              fontSize: "40px", fontWeight: 600, lineHeight: "48px", fontFamily: "Poppins",
              color: "#000000"
            }}>{TEXT_MESSAGES.PLAN_FEATURES1}</Typography>
            <PheonixTable data={secondTableData} />
          </Grid>
        </>
      )}
    </Grid>
      <Grid
        container ref={sections.pricingRef}
        spacing={2}
        style={{
          padding: isSmallScreen ? '48px 32px' : '20px 96px 64px',
          backgroundColor: themestyle.colors.background,
          justifyContent: 'space-around',
        }}
      >
        <Grid item>
          <Typography variant="h4"
            style={{
              fontFamily: 'Poppins',
              fontWeight: 700,
              fontSize: isSmallScreen ? themestyle.fontSizes.large : themestyle.fontSizes.xxlarge,
              color: themestyle.colors.purple,
              marginBottom: themestyle.spacing.small,
              textAlign: "center",
              marginTop: themestyle.spacing.small
            }}>{TEXT_MESSAGES.PRICING}</Typography>
          <Typography>{TEXT_MESSAGES.PRICING_TITLE}</Typography>
          <div style={{ marginTop: themestyle.spacing.xlarge, }}>
            <PheonixToggleSwitch selectedOption={selectedOption} handleToggle={handleToggle} /></div>
          <Grid container spacing={3} style={{ marginTop: '20px' }}>
            {cardsToDisplay.map((card, index) => (
              <Grid item key={index} xs={12} sm={6} md={4} lg={3} padding="20px">
                <PhoenixCard
                  title={card.title}
                  description1={card.description1}
                  style={{ width: "283px", height: "500px" }}
                  showButton={card.showButton}
                  buttonText={card.buttonText}
                  bulletPoints={card.bulletPoints}
                  amount={card.amount}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container ref={sections.contactRef}
        spacing={2}
        style={{
          padding: isSmallScreen ? '48px 32px' : '20px 96px 64px',
          backgroundColor: themestyle.colors.white,
          justifyContent: 'space-around',
        }}
      >
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h4"
            style={{
              fontFamily: 'Poppins',
              fontWeight: 700,
              fontSize: isSmallScreen ? themestyle.fontSizes.large : themestyle.fontSizes.large,
              color: themestyle.colors.textPrimary,
              marginBottom: themestyle.spacing.small,
              textAlign: "center",
              marginTop: themestyle.spacing.small
            }}
          >
            {TEXT_MESSAGES.join}
          </Typography>
          <Typography>
            {TEXT_MESSAGES.join1}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Formdata />
        </Grid>
      </Grid>
    </div>
  );
};
export default About;