import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import Layout from './components/Layout';
import About from './pages/About';

const App: React.FC = () => {
  const theme = createTheme();
 const sections = {
    aboutRef: React.createRef<HTMLDivElement>(),
    featureRef: React.createRef<HTMLDivElement>(),
    pricingRef: React.createRef<HTMLDivElement>(),
    contactRef: React.createRef<HTMLDivElement>()
  };
return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Layout sections={sections}>
          <Routes>
            <Route path="/" element={<About sections={sections} />} />
          </Routes>
        </Layout>
      </Router>
    </ThemeProvider>
  );
};
export default App;