import React from 'react';
import { Button } from '@mui/material';
import { PhoenixButtonProps } from "../interfaces/PhoenixButtonInterface"

const PhoenixButton: React.FC<PhoenixButtonProps> = ({ label, onClick, variant = 'contained', color = 'primary', ...props }) => {
  return (
    <Button variant={variant} color={color} onClick={onClick} {...props}>
      {label}
    </Button>
  );
};

export default PhoenixButton;
